class AInput {
	constructor(element) {
		this.element = element;

		const onInput = () => {
			this.autosize();
		};

		element.addEventListener('input', onInput);

		this.autosize();
	}

	autosize() {
		const { element } = this;
		const computedStyle = getComputedStyle(element);
		element.style.blockSize = 'auto';
		const blockSize = parseFloat(computedStyle.paddingBlockEnd) + element.scrollHeight;
		element.style.blockSize = `${blockSize}px`;
	}
}

[...document.querySelectorAll('.a-input[data-autosize]')].forEach((_) => new AInput(_));
