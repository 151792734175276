import { LitElement, html, nothing } from 'lit';
import { unsafeHTML } from 'lit/directives/unsafe-html.js';
import { ref } from 'lit/directives/ref.js';
import icons from '../foundation/icons.js';

const translations = {
	en: {
		close: 'Close modal',
	},
	de: {
		close: 'Dialog schließen',
	},
	es: {
		close: 'Cerrar modal',
	},
	fr: {
		close: 'Fermer le modal',
	},
};

class ViDialog extends LitElement {
	static properties = {
		showFooter: { type: Boolean, attribute: false },
		open: { type: Boolean },
	};

	dialogElement;

	/** Button element which opened the modal */
	targetButton = null;

	constructor() {
		super();
		this.showFooter = true;
	}

	get translations() {
		const language = this.closest('[lang]')?.lang ?? document.documentElement.lang;
		return translations[language] ?? translations.en;
	}

	get backdropElement() {
		return this.renderRoot.querySelector('.backdrop');
	}

	get contentElement() {
		return this.renderRoot.querySelector('.content');
	}

	#onBackdropClick(event) {
		if (event.target === this.backdropElement) {
			this.dialogElement.dispatchEvent(new Event('close'));
		}
	}

	#onButtonCloseClick() {
		this.dialogElement.dispatchEvent(new Event('close'));
	}

	#onSlotchange() {
		const assignedElementsCount = [...this.shadowRoot.querySelectorAll('.footer slot')]
			.reduce((accumulator, _) => accumulator + _.assignedElements().length, 0);
		this.showFooter = assignedElementsCount !== 0;
	}

	showModal() {
		if (!this.hasAttribute('open')) {
			this.targetButton = document.activeElement.tagName.toLowerCase() === 'button' ? document.activeElement : null;
			this.toggleAttribute('open', true);
			this.htmlElement.style.overflow = 'hidden';
			this.dialogElement.showModal();
			this.dialogElement.focus();
			this.backdropElement.animate({
				opacity: [0, 1],
			}, {
				duration: 100,
				easing: 'ease-in',
			});
			this.contentElement.animate({
				translate: ['0 1rem', '0 0'],
				opacity: [0, 1],
			}, {
				duration: 250,
				delay: 50,
				fill: 'backwards',
				easing: 'ease-out',
			});
		}
	}

	close() {
		if (this.hasAttribute('open')) {
			this.contentElement.animate({
				translate: ['0 0', '0 1rem'],
				opacity: ['1', '0'],
			}, {
				duration: 200,
				delay: 20,
				easing: 'linear',
			});
			const animation = this.backdropElement.animate({
				opacity: 0,
			}, {
				duration: 200,
				easing: 'linear',
			});

			animation.addEventListener('finish', () => {
				this.toggleAttribute('open', false);
				this.htmlElement.style.overflow = null;
				this.dialogElement.close();

				this.dispatchEvent(new CustomEvent('close'));

				this.targetButton?.focus();
			});
		}
	}

	onClose(event) {
		// Close event of this.dialogElement
		event.preventDefault();
		this.close();
	}

	connectedCallback() {
		super.connectedCallback();

		this.htmlElement = document.querySelector('html');

		this.addEventListener('keydown', (event) => {
			if (event.key === 'Escape') {
				event.preventDefault();
				event.stopPropagation();
				this.close();
			}
		});

		this.dispatchEvent(new CustomEvent('connected'));
	}

	render() {
		const { showFooter } = this;

		return html`
			<link rel="stylesheet" href="/assets/css/vi-dialog.${BUILT_AT}.css">
			<dialog ${ref((_) => { this.dialogElement = _; })} tabindex="0" @close=${this.onClose}>
				<div class="backdrop" @click=${this.#onBackdropClick}>
					<div class="content">
						<div class="header">
							<slot name="header"></slot>
							<button
								class="a-button"
								data-shape="round"
								data-kind="tertiary"
								data-size="large"
								aria-label="${this.translations.close}"
								@click=${this.#onButtonCloseClick}
							>${unsafeHTML(icons.close)}</button>
						</div>
						<slot></slot>
						<div class="footer" .hidden="${!showFooter ?? nothing}">
							<slot name="secondary-buttons" @slotchange=${this.#onSlotchange}></slot>
							<slot name="primary-button" @slotchange=${this.#onSlotchange}></slot>
						</div>
					</div>
				</div>
			</dialog>
		`;
	}
}

customElements.define('vi-dialog', ViDialog);
