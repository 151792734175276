/** @typedef {import("../custom-elements/vi-wishlist").default} ViWishlist */

class MPconConfigurator {
	/**
	 * @param {HTMLElement} element
	 */
	constructor(element) {
		/** @type {HTMLIFrameElement} iframeElement */
		const iframeElement = element.querySelector('iframe');
		const origin = iframeElement.contentWindow.origin;

		window.addEventListener('message', (event) => {
			/** @var {MessageEvent} event */
			if (event.origin !== origin) return;
			if (event.data.status === 'success') {
				(async () => {
					/** @type {ViWishlist} */
					const wishlist = document.querySelector('vi-wishlist[variant="active"]');
					await wishlist.loadData();
					wishlist.open();
				})();
			}
		});
	}
}

[...document.querySelectorAll('.m-pcon-configurator')].map((element) => new MPconConfigurator(element));
