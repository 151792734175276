import {
	LitElement,
	html,
	nothing,
} from 'lit';
import { unsafeHTML } from 'lit/directives/unsafe-html.js';
import icons from '../foundation/icons.js';

class ViRelatedPages extends LitElement {
	scrollContainerElement;

	snapPoints;

	_columns = 0;

	static properties = {
		currentIndex: { type: Number },
		columns: { type: Number },
	};

	set columns(val) {
		this._columns = parseInt(val, 10);
	}

	get columns() {
		if (window.matchMedia('not (min-width: 40.625em)').matches) {
			return 1;
		}
		return this._columns;
	}

	constructor() {
		super();
		this.currentIndex = 1;
		this.columns = 3;
	}

	scrollToPreviousSnapElement() {
		const { scrollContainerElement, snapPoints } = this;
		const currentIndex = this.currentIndex - 1;
		const previousSnapPoint = snapPoints[currentIndex - 1];
		if (previousSnapPoint) {
			scrollContainerElement.scrollTo({
				left: previousSnapPoint.offsetLeft,
				behavior: 'smooth',
			});
		}
	}

	scrollToNextSnapElement() {
		const { scrollContainerElement, snapPoints } = this;
		const currentIndex = this.currentIndex - 1;
		const nextSnapPoint = snapPoints[currentIndex + 1];
		if (nextSnapPoint) {
			scrollContainerElement.scrollTo({
				left: nextSnapPoint.offsetLeft,
				behavior: 'smooth',
			});
		}
	}

	getCurrentSnappedIndex() {
		const { scrollContainerElement, snapPoints } = this;
		const { scrollLeft } = scrollContainerElement;

		// Find the index of the child that is closest to the current scroll position
		let closestChildIndex = 0;
		let smallestDifference = Infinity;

		snapPoints.forEach((child, index) => {
			const difference = Math.abs(scrollLeft - child.offsetLeft);
			if (difference < smallestDifference) {
				smallestDifference = difference;
				closestChildIndex = index;
			}
		});

		return closestChildIndex;
	}

	onPreviousClick() {
		this.scrollToPreviousSnapElement();
	}

	onNextClick() {
		this.scrollToNextSnapElement();
	}

	connectedCallback() {
		super.connectedCallback();

		const setSnapPoints = () => {
			const { columns } = this;
			this.snapPoints = [...this.listElement.querySelectorAll(`li:nth-child(${columns}n - ${columns - 1})`)];
		};

		this.listElement = this.querySelector('[slot="list"]');
		setSnapPoints();

		window.addEventListener('resize', () => {
			setSnapPoints();
			this.requestUpdate();
		});
	}

	firstUpdated() {
		this.scrollContainerElement = this.renderRoot.querySelector('.scroll-container');

		this.currentIndex = this.getCurrentSnappedIndex() + 1;

		const isElementPartiallyInViewport = (element) => {
			const rect = element.getBoundingClientRect();
			const windowHeight = window.innerHeight || document.documentElement.clientHeight;
			const windowWidth = window.innerWidth || document.documentElement.clientWidth;

			return (
				rect.top < windowHeight
				&& rect.bottom > 0
				&& rect.left < windowWidth
				&& rect.right > 0
			);
		};

		this.scrollContainerElement.addEventListener('scroll', () => {
			this.currentIndex = this.getCurrentSnappedIndex() + 1;
		}, {
			passive: true,
		});

		const onKeyDown = (event) => {
			if (!event.shiftKey && !event.altKey && !event.ctrlKey && !event.metaKey) {
				if (isElementPartiallyInViewport(this.scrollContainerElement)) {
					if (event.key === 'ArrowLeft') {
						event.preventDefault();
						this.onPreviousClick();
					} else if (event.key === 'ArrowRight') {
						event.preventDefault();
						this.onNextClick();
					}
				}
			}
		};

		document.addEventListener('keydown', onKeyDown);

		// init
		setTimeout(() => {
			// Fix for Safari. Make sure it starts at first item.
			this.scrollContainerElement.scrollTo({
				left: 0,
				bevahior: 'instant',
			});
		}, 100);
	}

	render() {
		const { currentIndex } = this;
		const snapPointsCount = this.snapPoints.length;

		return html`
			<link rel="stylesheet" href="/assets/css/vi-related-pages.${BUILT_AT}.css">
			<aside class="container">
				<header>
					<slot name="heading"></slot>
					<slot name="link-all"></slot>
				</header>
				<div class="scroll-container">
					<slot name="list"></slot>
				</div>
				${snapPointsCount > 1 ? html`
					<nav aria-hidden="true">
						<span>${currentIndex}/${snapPointsCount}</span>
						<button class="a-button" data-shape="squared" aria-disabled="${currentIndex === 1 ? true : nothing}" aria-label="previous" @click=${this.onPreviousClick}>
							<span class="a-button__icon" data-variant="arrow-left">
								${unsafeHTML(icons['arrow-left'])}
							</span>
						</button>
						<button class="a-button" data-shape="squared" aria-disabled="${currentIndex === snapPointsCount ? true : nothing}" aria-label="next" @click=${this.onNextClick}>
							<span class="a-button__icon" data-variant="arrow-right">
								${unsafeHTML(icons['arrow-right'])}
							</span>
						</button>
					</nav>
				` : null}
			</aside>
		`;
	}
}

// Define the custom element
if (!customElements.get('vi-related-pages')) {
	customElements.define('vi-related-pages', ViRelatedPages);
}
