import { html, render, nothing } from 'lit';
import { unsafeHTML } from 'lit/directives/unsafe-html.js';
import icons from '../foundation/icons.js';
// import '../custom-elements/vi-input.js';

const wishlist = document.querySelector('vi-wishlist[variant="active"]');
const cart = document.querySelector('vi-cart[variant="active"]');

class MProduct {
	data = null;

	storage = {};

	/**
	 * @param {Object|HTMLElement} data - data to construct element or dom element
	 * @param {string} systemId
	 * @param {string} assortment
	 */
	constructor(data, systemId, assortment) {
		if (data instanceof HTMLElement) {
			this.element = data;
			this.element.addEventListener('submit', this.#onSubmit.bind(this));
			this.element.querySelector('input[name="length-inch"]')?.addEventListener('input', this.#onInputLenghtInchInput.bind(this));
		} else {
			this.data = data;
			this.systemId = systemId;
			this.assortment = assortment;

			this.element = this.generateElement();
		}
	}

	get #mmInfoElement() {
		return this.element.querySelector('.m-product__mm-info');
	}

	get #inputLenghtElement() {
		return this.element.querySelector('input[name="length"]');
	}

	get maxAmount() {
		return this.data.maxAmount;
	}

	get quantityOptions() {
		return Array.from({ length: this.maxAmount }, (_, i) => i + 1).map((i) => ({
			value: i,
			text: i,
		}));
	}

	/** @param {Number} inch */
	inchToMm(inch) {
		return Math.round(inch * 25.4, 10);
	}

	generateElement() {
		const {
			data,
			quantityOptions,
			systemId,
			assortment,
		} = this;

		const {
			title,
			size = null,
			price = null,
			unitCode = null,
			details = null,
			options = null,
		} = data;

		const element = Object.assign(document.createElement('form'), {
			className: 'm-product',
			method: 'post',
			id: data.id,
		});
		Object.assign(element.dataset, {
			system: systemId,
			assortment,
		});
		element.addEventListener('submit', this.#onSubmit.bind(this));

		render(html`
			<div class="m-product__container">
				<div class="m-product__title">
					<span class="m-product__size">${size}</span>
					<span class="m-product__article">
						<span class="a-visually-hidden">${translations['product.articleNumber']} </span>
						${title}
					</span>
				</div>

				<span class="m-product__price">
					${price !== null ? html`
						${price}
						${unitCode === 'M' ? html`<small>${translations['product.perMeter']}</small>` : ''}
					` : html`${translations['product.priceUponRequest']}`}

					${assortment === 'us-ca' ? html`
						<div class="m-product__mm-info"></div>
					` : null}
				</span>

				${unitCode === 'M' ? html`
					<span class="m-product__selector">
						<vi-input
							size="small"
							variant="inline"
						>
							<span slot="label">${translations['product.length']}</span>
							${assortment === 'us-ca' ? html`
								<input
									slot="input"
									class="a-input"
									type="number"
									name="length-inch"
									required
									placeholder="0"
									step="0.01"
									data-size="small"
									@input="${this.#onInputLenghtInchInput.bind(this)}"
								>
								<input type="hidden" name="length">
								<span slot="end">inch</span>
							` : html`
								<input
									slot="input"
									class="a-input"
									type="number"
									name="length"
									required
									min="${details.min ?? nothing}"
									max="${details.max ?? nothing}"
									placeholder="0"
									data-size="small"
								>
								<span slot="end">mm</span>
							`}
						</vi-input>
					</span>
				` : null}
				${options ? html`
					<span class="m-product__options">
						<label class="field" data-variant="inline" data-size="x-small">
							<select aria-label="${options.label}" class="field__input a-select" data-size="small" name="option" required>
								<option disabled value="" selected>${options.label} …</option>
								${options.options?.map((item) => html`<option value="${item.key}">${item.value}</option>`)}
							</select>
						</label>
					</span>
				` : null}

				<div class="m-product__buttons-select">
					<select class="a-select" data-size="small" name="quantity" data-text-align="end" aria-label="${translations['product.amount']}">
						${quantityOptions?.map((item) => html`<option value=${item.value} ?selected=${item.value === 1}>${item.text}</option>`)}
					</select>
					<div class="m-product__buttons">
						<vi-tooltip id="tooltip-cart-${title}" hidden>${translations['product.add-to-cart']}</vi-tooltip>
						<button data-tooltip-target="tooltip-cart-${title}" data-action="cart" class="a-button" data-shape="squared" data-size="small" disabled="${price === null ? true : nothing}">
							${unsafeHTML(icons['cart-add'])}
						</button>
						<vi-tooltip id="tooltip-wishlist-${title}" hidden>${translations['product.add-to-wishlist']}</vi-tooltip>
						<button data-tooltip-target="tooltip-wishlist-${title}" data-action="wishlist" class="a-button" data-kind="secondary" data-shape="squared" data-size="small">
							${unsafeHTML(icons['heart-add'])}
						</button>
					</div>
				</div>
			</div>
		`, element);

		return element;
	}

	#onInputLenghtInchInput(event) {
		if (this.#mmInfoElement && this.#inputLenghtElement) {
			const lengthInInch = parseFloat(event.target.value);
			const lengthInMm = this.inchToMm(lengthInInch);
			this.#inputLenghtElement.value = lengthInMm;
			if (lengthInMm) {
				this.#mmInfoElement.innerText = `${lengthInInch} inch = ${lengthInMm} mm`;
			} else {
				this.#mmInfoElement.innerText = null;
			}
		}
	}

	async #onSubmit(event) {
		event.preventDefault();

		// custom form validation for web components
		const isValid = [...event.target.elements].every((_) => _.validity.valid);

		if (isValid) {
			event.submitter.toggleAttribute('data-loader');
			if (event.submitter.dataset.action === 'wishlist') {
				await this.addToWishlist();
			}
			if (event.submitter.dataset.action === 'cart') {
				await this.addToCart();
			}
			event.submitter.removeAttribute('data-loader');
		} else {
			// TODO
			alert('invalid');
		}
	}

	/**
	 * Prepares data for Wishlist or Cart
	 * @returns {object} item to be added to cart
	 */
	prepareDate(element) {
		const { lang } = document.querySelector('html');

		const { id } = element;

		const {
			currency,
			system = null,
			assortment = null,
		} = element.dataset;
		const quantity = element.querySelector('[name="quantity"]').value;
		const option = element.querySelector('[name="option"]')?.value ?? null;
		const length = element.querySelector('[name="length"]')?.value ?? null;
		const data = {
			system,
			assortment,
			lang,
			option,
			length,
		};

		return {
			id,
			quantity,
			currency,
			data,
		};
	}

	async addToWishlist() {
		const {
			element,
		} = this;

		const {
			id,
			quantity,
			currency,
			data,
		} = this.prepareDate(element);

		const responseData = await wishlist.addItem(id, quantity, currency, data);
		if (responseData.status === 'ok') {
			// open wishlist
			wishlist.open();
		}
	}

	async addToCart() {
		const {
			element,
		} = this;

		const {
			id,
			quantity,
			currency,
			data,
		} = this.prepareDate(element);

		const responseData = await cart.addItem(id, quantity, currency, data);
		if (responseData.status === 'ok') {
			// open cart
			cart.open();
		}
	}
}

export default MProduct;
