import { html, render, nothing } from 'lit';
import { unsafeHTML } from 'lit/directives/unsafe-html.js';
import icons from '../foundation/icons.js';

/**
 * @typedef ViWishlist
 * @see ../custom-elements/vi-wishlist.js
 */

class OWishlist {
	constructor(element) {
		this.element = element;

		/** @type HTMLElement */
		this.asideElement = element.querySelector('.o-wishlist__aside');

		/** @type ViWishlist */
		this.activeWishlist = document.querySelector('vi-wishlist[variant="active"]');

		/** @type ViWishlist */
		this.savedWishlist = document.querySelector('vi-wishlist[page]');

		/** @type HTMLElement */
		this.activationStatusElement = document.querySelector('.o-wishlist__activation-status');

		this.formUpdateNoteElement = document.querySelector('vi-form[name="update-note"]');

		// Event Listeners
		this.activeWishlist.addEventListener('change', this.render.bind(this));
		this.savedWishlist.addEventListener('change', this.render.bind(this));
		this.formUpdateNoteElement.addEventListener('success', () => {
			const { successElement, submitElement } = this.formUpdateNoteElement;
			const successMessage = successElement.innerHTML;
			successElement.remove();
			submitElement.removeAttribute('hidden');
			submitElement.removeAttribute('data-loader');
			this.formUpdateNoteElement.unsetFieldsToReadonly();
			const toast = Object.assign(document.createElement('vi-toast'), {
				innerHTML: successMessage,
			});
			toast.setAttribute('theme', 'positive');
			document.querySelector('.m-toasts')?.prepend(toast);
		});

		// this.render();
	}

	get isSynced() {
		const {
			activeWishlist,
			savedWishlist,
		} = this;

		return activeWishlist.wishlistPageId === savedWishlist.wishlistPageId;
	}

	async #syncWishlists() {
		await this.savedWishlist.request('activate', 'get').catch((error) => {
			console.error(error);
		});
		this.activeWishlist.loadData();
	}

	#unsyncWishlists() {
		this.activeWishlist.unlink();
	}

	#toogleSync() {
		if (this.isSynced) {
			this.#unsyncWishlists();
		} else {
			this.#syncWishlists();
		}
	}

	render() {
		const {
			activationStatusElement,
			isSynced,
			activeWishlist,
			savedWishlist,
		} = this;

		if (activeWishlist.dataLoaded > 0 && savedWishlist.dataLoaded > 0) {
			render(html`
				<div class="m-banner" data-theme="${isSynced ? 'positive' : 'inverse'}" data-kind="leading-icon">
					<div class="m-banner__icon">
						<div class="a-icon" data-kind="${isSynced ? nothing : 'secondary'}" data-inverse="${isSynced ? nothing : true}" data-size="xxx-large" data-variant="wishlist" data-has-items="${isSynced ? true : nothing}">
							${unsafeHTML(icons.heart)}
						</div>
					</div>
					<div class="a-text">
						<p>${isSynced ? translations['wishlist.activation-status.synced.title'] : translations['wishlist.activation-status.unsynced.title']}</p>
						<p>${isSynced ? translations['wishlist.activation-status.synced.text'] : translations['wishlist.activation-status.unsynced.text']}</p>
						<p>
							<button class="a-button" data-shape="text" data-inverse="${isSynced ? nothing : true}" @click="${this.#toogleSync.bind(this)}">
								<span>${isSynced ? translations['wishlist.activation-status.synced.button'] : translations['wishlist.activation-status.unsynced.button']}</span>
							</button>
						</p>
					</div>
				</div>
			`, activationStatusElement);
		}
	}
}

const element = document.querySelector('.o-wishlist');
if (element) {
	// eslint-disable-next-line no-new
	new OWishlist(element);
}
