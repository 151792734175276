const translations = {
	en: {
		close: 'Close modal',
	},
	de: {
		close: 'Dialog schließen',
	},
	es: {
		close: 'Cerrar modal',
	},
	fr: {
		close: 'Fermer le modal',
	},
};

class MDrawerWishlist {
	constructor(element) {
		const buttonToggleElement = document.querySelector('.a-button[data-action="toggle-wishlist"]');

		this.element = element;
		this.buttonCloseElement = element.querySelector('.a-button[data-action="close"]');
		this.buttonCloseElement.setAttribute('aria-label', this.translations.close);

		this.initMutationObserver();

		buttonToggleElement.addEventListener('click', () => {
			this.element.showModal();
		});
		element.addEventListener('click', (event) => {
			if (event.target === element) {
				this.close();
			}
		});
		element.addEventListener('close', () => {
			document.documentElement.style.overflow = null;
			element.toggleAttribute('data-open', false);
		});
		element.addEventListener('open', () => {
			document.documentElement.style.overflow = 'hidden';
			this.element.focus();
			requestAnimationFrame(() => {
				element.toggleAttribute('data-open', true);
			});
		});
		element.addEventListener('transitionend', () => {
			if (this.element.hasAttribute('data-open') === false) {
				this.element.close();
			}
		});
		this.buttonCloseElement.addEventListener('click', this.close.bind(this));

		this.element.addEventListener('keydown', (event) => {
			if (event.key === 'Escape') {
				event.preventDefault();
				event.stopPropagation();
				this.close();
			}
		});
	}

	get translations() {
		const language = this.element.closest('[lang]')?.lang ?? document.documentElement.lang;
		return translations[language] ?? translations.en;
	}

	initMutationObserver() {
		const { element } = this;

		const observer = new MutationObserver((mutationsList) => {
			mutationsList.forEach((mutation) => {
				if (mutation.type === 'attributes' && mutation.attributeName === 'open') {
					// Check if the open attribute is present (dialog is open)
					if (element.hasAttribute('open')) {
						// Dispatch an 'open' event
						const openEvent = new Event('open');
						element.dispatchEvent(openEvent);
					}
				}
			});
		});

		observer.observe(element, { attributes: true, attributeFilter: ['open'] });
	}

	close() {
		this.element.toggleAttribute('data-open', false);
		if (window.matchMedia('(prefers-reduced-motion)').matches) {
			this.element.close();
		}
	}
}

const element = document.querySelector('.m-drawer-wishlist');
if (element) {
	// eslint-disable-next-line no-new
	new MDrawerWishlist(element);
}
