import RoomleConfiguratorApi from '@roomle/embedding-lib';
import { html, render, nothing } from 'lit';
import { ref, createRef } from 'lit/directives/ref.js';
import { unsafeHTML } from 'lit/directives/unsafe-html.js';
import icons from '../foundation/icons.js';

class ORoomleConfigurator {
	/** @type {RoomleConfiguratorApi} */
	configurator = null;

	cachedProductLists = [];

	#buttonsElement;

	#buttonAddToCartElement;

	#language = document.documentElement.lang;

	objects = [];

	constructor(element) {
		this.element = element;
		this.oRoomlePlanElement = element.querySelector('.o-roomle-plan');
		this.containerElement = element.querySelector('.o-roomle-configurator__container');
		this.#buttonsElement = element.querySelector('.o-roomle-configurator__buttons');
		this.#buttonAddToCartElement = element.querySelector('button[data-action="add-to-cart"]');

		const configuratorData = JSON.parse(element.dataset.configurator);

		/**
		 * Formats a length in millimeters as a
		 * human-readable string in cm
		 * @param {Number} millimeters
		 * @returns {string} in cm
		 */
		this.formatLength = (millimeters) => `${(parseFloat(millimeters) / 10).toLocaleString(this.#language)} cm`;

		/**
		 * @param {Number} price
		 * @param {String} currency – default 'EUR'
		 * @returns {string} in cm
		 */
		this.formatPrice = (price, currency = 'EUR') => price.toLocaleString(this.#language, {
			style: 'currency',
			currency,
		});

		this.init(configuratorData);
	}

	async init(configuratorData) {
		const {
			element,
			containerElement,
		} = this;
		const {
			configuratorId,
			options,
		} = configuratorData;
		const elementId = element.id;

		const currentId = this.idFromUrl() || options.id;
		const deeplink = this.idToUrl(
			`${this.elementId}-PLACEHOLDER`,
			false,
		).href.replace(`${this.elementId}-PLACEHOLDER`, '#CONFIGURATIONID#');

		Object.assign(options, {
			fonts: {
				source: `${location.origin}/assets/css/fonts.css`,
				family: 'vFutura, Arial, sans-serif',
			},
			gaConsent: false,
			deeplink,
			id: currentId,
		});

		const configurator = await RoomleConfiguratorApi.createConfigurator(
			configuratorId, // your configurator id which you got
			containerElement, // the specified domElement id
			options, // custom parameter for UI customisation
		);

		element.configurator = configurator;
		this.configurator = configurator;

		// Events
		configurator.ui.callbacks.onPlanElementAdded = (addedObject) => {
			this.objects[addedObject.configurationRuntimeId] = addedObject;
			this.updateObject(addedObject);
		};
		configurator.ui.callbacks.onPlanElementRemoved = (removedObject) => {
			delete this.objects[removedObject.configurationRuntimeId];
			this.renderRoomlePlan();
		};
		this.configurator.ui.callbacks.onSaveDraft = this.#onSaveDraft.bind(this);

		configurator.ui.callbacks.onPartListUpdate = async () => {
			const objects = await configurator.extended.getObjects();
			objects.forEach((object) => {
				this.updateObject(object);
			});
		};

		this.containerElement.addEventListener('mouseout', this.updatePerspectiveImage.bind(this));
		this.oRoomlePlanElement.addEventListener('touchstart', this.updatePerspectiveImage.bind(this));

		// Button events
		this.element.querySelector('button[data-action="request-plan"]')?.addEventListener('click', (event) => {
			event.target.toggleAttribute('data-loader', true);
			this.configurator.ui.triggerRequestPlan();
		});
		this.element.querySelector('button[data-action="save-draft"]')?.addEventListener('click', async (event) => {
			event.target.toggleAttribute('data-loader', true);
			await this.configurator.ui.triggerSaveDraft();
			event.target.toggleAttribute('data-loader', false);
		});
		this.element.querySelector('button[data-action="add-to-cart"]')?.addEventListener('click', async (event) => {
			event.target.toggleAttribute('data-loader', true);
			await this.addItemsToCart();
			event.target.toggleAttribute('data-loader', false);
		});
	}

	/**
	 * @param {KernelPart[]} fullList
	 * @param {String} configurationHash The hash of the configuration string.
	 * @returns {Promise<string>} (Cached) Api response
	 * @see https://docs.roomle.com/web/api/interfaces/typings_kernel.KernelPartList.html#fulllist
	 */
	async fetchProductList(fullList, configurationHash) {
		/* Check if productList for this configurationHash was loaded before. Double check length */
		if (this.cachedProductLists[configurationHash]
			&& this.cachedProductLists[configurationHash].data.items.length === fullList.length) {
			return new Promise((resolve) => {
				resolve(this.cachedProductLists[configurationHash]);
			});
		}

		const json = fetch('/api/catalog/products-from-partlist?view=roomleConfigurator', {
			method: 'POST',
			headers: {
				'x-language': this.#language,
			},
			body: JSON.stringify(fullList),
		}).then((response) => response.json());

		this.cachedProductLists[configurationHash] = await json;

		return json;
	}

	/** @returns {string} Formatted sum */
	get formattedSum() {
		/** @property {UiPlanObject[]} objects */
		const { objects } = this;
		const { formatPrice } = this;
		let sum = 0;
		objects.forEach((object) => {
			sum += object?.productList?.sumFloat ?? 0;
		});

		if (this.isPriceUponRequest || sum === 0) {
			return `<strong>${translations['product.priceUponRequest']}</strong> (${translations['wishlist.price-from']} ${formatPrice(sum)})`;
		}
		return `<strong>${formatPrice(sum)}</strong>`;
	}

	get isPriceUponRequest() {
		const { objects } = this;

		let isPriceUponRequest = false;
		objects.forEach((object) => {
			if (object?.productList?.sumFloat === null
				|| object?.productList?.isPriceUponRequest === true) {
				isPriceUponRequest = true;
			}
		});

		return isPriceUponRequest;
	}

	/**
	 * Returns the list of items with duplicates
	 * merged to a single item including the count
	 *
	 * @see https://github.com/lukasbestle/kirby-roomle/blob/86841b7319b1b00353530d6bef6b80d192c8ae16/src/classes/Plan.php#L112
	 * @returns {Array}
	 */
	get groupedObjects() {
		const groupedObjects = {};
		this.objects.forEach((object) => {
			if (groupedObjects[object.configurationHash]) {
				groupedObjects[object.configurationHash].count += 1;
			} else {
				groupedObjects[object.configurationHash] = object;
				groupedObjects[object.configurationHash].count = 1;
			}
		});
		return Object.values(groupedObjects);
	}

	updateButtons() {
		this.#buttonsElement.toggleAttribute('hidden', !(this.objects.length > 0));
		this.#buttonAddToCartElement.toggleAttribute('hidden', this.isPriceUponRequest === true);
	}

	/**
	 * called when triggerSaveDraft called
	 * @param {string} id  configuration id or plan id
	 * @param {string} image image of the current configuration
	 * @param {string} url generated url from save draft
	 * @see https://docs.roomle.com/rubens/rubens-products/rubens-products-reference/classes/exposed_callbacks.exposedcallbacks#onsavedraft
	 */
	#onSaveDraft(id, image, url) {
		this.showSaveDialog(url);

		if (window.plausible) {
			window.plausible('Saved Roomle Configuration', { props: { roomleUrl: url } });
		}
	}

	async addItemsToCart() {
		/** @type {ViCart} */
		const cartElement = document.querySelector('vi-cart');

		const items = this.objects.reduce((accumulator, currentValue) => [
			...accumulator,
			...currentValue.productList.items,
		], []);
		// console.log(items);

		/** Cart Response data */
		const responseData = await cartElement.addItems(items);

		const isCartDrawerOpen = cartElement.open();

		if (isCartDrawerOpen === false) {
			/** Cart drawer does not exists => checkout page. */
			this.drawerElement.dispatchEvent(new Event('click'));
		}

		return responseData;
	}

	showSaveDialog(url) {
		const urlObject = new URL(url);
		console.log(urlObject);
		const shortUrl = `${urlObject.hostname}${urlObject.pathname}${urlObject.search}`;

		const dialogElement = createRef();

		const onButtonShareClick = async () => {
			try {
				await navigator.share({
					title: translations['configuration.share-title'],
					url,
				});
			} catch (error) {
				console.info(error);
			}
		};
		const onButtonCopyLinkClick = async () => {
			try {
				await navigator.clipboard.writeText(url);
				const toast = Object.assign(document.createElement('vi-toast'), {
					innerText: translations['saved-to-clipboard'],
				});
				toast.toggleAttribute('inverse', true);
				dialogElement.value.querySelector('.m-toasts')?.prepend(toast);
			} catch (error) {
				console.error(error);
			}
		};

		render(html`
			<vi-dialog ${ref(dialogElement)} size="medium">
				<span slot="header">${translations['configuration.save-dialog.title']}</span>
				<div class="a-text">
					<p>${translations['configuration.save-dialog.text']}</p>
					<p><strong><a href="${url}">${shortUrl}</a></strong></p>
				</div>
				<div class="m-toasts" role="log" aria-live="polite"></div>
				<div slot="secondary-buttons">
					${typeof navigator.share === 'function' ? html`
						<button
							class="a-button"
							data-kind="secondary"
							@click=${onButtonShareClick}
						>
							${translations.share}
						</button>
					` : null}
					<button
						class="a-button"
						data-kind="secondary"
						@click=${onButtonCopyLinkClick}
					>
						${translations['copy-link']}
					</button>
				</div>
			</vi-dialog>
		`, document.body);

		/** wait for dialogElement to be rendered */
		requestAnimationFrame(() => {
			dialogElement.value.showModal();
		});
	}

	async updatePerspectiveImage() {
		/** @type {UiPlanObject[]} */
		const { objects } = this;

		/* eslint-disable no-restricted-syntax */
		for (const object of Object.values(objects)) {
			try {
				if (object?.imageNeedsUpdate === true) {
					const { configurationRuntimeId, runtimeId } = object;
					const {
						image: perspectiveImage,
					} = await this.configurator.extended.preparePerspectiveImageOf(runtimeId, {
						size: 120,
					});

					console.log(performance.now(), perspectiveImage);

					const data = Object.assign(this.objects[configurationRuntimeId]?.data ?? {}, {
						perspectiveImage,
					});
					Object.assign(this.objects[configurationRuntimeId], {
						data,
						imageNeedsUpdate: false,
					});
					this.renderRoomlePlan();
				}
			} catch (error) {
				console.info(error);
			}
		}
	}

	async updateObject(object) {
		const { configurationRuntimeId, parts, configurationHash } = object;
		const oldData = this.objects[configurationRuntimeId].data ?? {};
		Object.assign(this.objects[configurationRuntimeId], object);
		if (object.data === null) {
			Object.assign(this.objects[configurationRuntimeId], {
				data: oldData,
				imageNeedsUpdate: true,
			});
		}
		if (parts.fullList.length > 0) {
			const response = await this.fetchProductList(parts.fullList, configurationHash);
			const productList = response?.data;
			if (productList) {
				Object.assign(this.objects[configurationRuntimeId], {
					productList,
				});
				this.updateButtons();
			}
		}

		this.renderRoomlePlan();
	}

	/**
	 * Loads a new item or configuration into the configurator
	 *
	 * @param {String} id Item or configuration ID
	 * @returns {Boolean} Whether the configurator was updated
	 */
	async load(id) {
		this.currentId = id;

		if (!this.configurator) {
			// not yet initialized
			return false;
		}

		await this.configurator.ui.loadObject(id);

		return true;
	}

	/**
	 * Extracts the item/configuration from the current URL
	 *
	 * @returns {String|null}
	 */
	idFromUrl() {
		const params = new URLSearchParams(window.location.search);
		return params.get(this.element.id);
	}

	/**
	 * Builds a URL for a specific item or configuration in this block
	 *
	 * @param {String} id Item or configuration ID
	 * @param {Boolean} hash Whether to include the hash of the current block
	 * @returns {URL}
	 */
	idToUrl(id, hash = false) {
		const url = new URL(window.location.href);
		url.searchParams.set(this.element.id, id);

		if (hash === true) {
			url.hash = this.element.id;
		}

		return url;
	}

	renderRoomlePlan() {
		// console.log('render');
		render(html`
			${this.objects.length > 0 ? html`
				<h2 class="a-heading" data-size="large">${translations['configuration-request.configuration-data']}</h2>
				<p class="o-roomle-plan__sum">${unsafeHTML(this.formattedSum)}</p>
				${[...this.groupedObjects].map((object) => this.renderRoomleConfiguration(object))}
			` : null}
		`, this.oRoomlePlanElement);
	}

	/**
	 * @param {UiPlanObject} object
	 * @see https://docs.roomle.com/web/embedding/api/classes/exposed_callbacks.ExposedCallbacks.html#onplanupdate
	 */
	renderRoomleConfiguration(object) {
		const { formatLength } = this;
		const {
			data,
			dimensions,
			parts,
			productList,
			count = 1,
		} = object;

		if (parts.fullList.length === 0) {
			return null;
		}

		const imageSrc = data?.perspectiveImage;

		return html`
			<div class="m-roomle-configuration" .object="${object}">
				<div class="m-roomle-configuration__image" data-loader="${object.imageNeedsUpdate ? true : nothing}">
					<img width="1024" height="1024" src="${imageSrc ?? 'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=='}" loading="lazy">
				</div>
				<div class="m-roomle-configuration__summary">
					<h3 class="m-roomle-configuration__heading">${count}× ${data?.label}</h3>
					<div class="m-roomle-configuration__info">
						${dimensions?.x ? html`
							<p>${this.#language === 'de' ? `B ${formatLength(dimensions?.x)} / H ${formatLength(dimensions?.z)} / T ${formatLength(dimensions?.y)}` : `W ${formatLength(dimensions?.x)} / H ${formatLength(dimensions?.z)} / D ${formatLength(dimensions?.y)}`}</p>` : null}
						${productList?.sum ? html`
							<p>
								${productList.isPriceUponRequest === true ? html`
									<strong>${translations['product.priceUponRequest']}</strong> ${productList.sumFloat > 0 ? `(${translations['wishlist.price-from']} ${productList.sum})` : null}
								` : html`
									<strong>${productList.sum}</strong>
								`}
							</p>` : html`<div class="m-stack"><span class="a-icon" data-kind="loader">${unsafeHTML(icons.loader)}</span></div>`}
					</div>
				</div>
				${productList ? html`
					<div class="m-roomle-configuration__details">
						<details class="m-details">
							<summary>
								<div>${translations['configuration-request.partlist']}</div>
								<span class="a-icon">${unsafeHTML(icons['arrow-drop-down'])}</span>
							</summary>
							<div class="m-details__content">
								<ul class="m-product-list">
									${productList.items.map((item) => html`
										<li>
											<vi-product-list-item
												readonly="true"
												.productImage="${item.productImage}"
												productTitle="${item.title}"
												title="${item.subtitle}"
												articlenumber="${item.articleNumber}"
												.parameters="${item.parameters}"
												price="${item.price}"
												quantity="${item.quantity}"
												sum="${item.sum ?? translations['product.priceUponRequest']}"
												url="${item.url}"
											>
											</vi-product-list-item>
										</li>
									`)}
								</ul>
							</div>
						</details>
					</div>
				` : null}
			</div>
		`;
	}
}

document.querySelectorAll('.o-roomle-configurator').forEach((_) => new ORoomleConfigurator(_));
