import {
	LitElement,
	html,
} from 'lit';

/**
 * @typedef {Object} ApiResponse
 * @property {String} status ’ok‘ or ’error‘
 * @property {Number} code HTTP response status code
 * @property {String} [message] General error message or success message
 * @property {String} [key] Error key
 * @property {Object} [details] Keys are field names, values are error messages
 */

class ViForm extends LitElement {
	submitElement;

	errorElement;

	successElement;

	get formElement() {
		return this.querySelector('*:not([slot])');
	}

	get language() {
		return this.formElement?.closest('[lang]')?.lang ?? 'en';
	}

	async loadSpamPrevention() {
		const response = await fetch('/api/form/spam-prevention', {
			method: 'get',
		});

		const json = await response.json();

		Object.keys(json).forEach((key) => {
			const hiddenInputElement = Object.assign(document.createElement('input'), {
				name: key,
				value: json[key],
				type: 'hidden',
			});
			this.formElement.appendChild(hiddenInputElement);
		});
	}

	connectedCallback() {
		this.formElement.addEventListener('submit', this.onSubmit.bind(this));

		// Load spam prevent when user focuses the first input element
		this.formElement.elements.item(0).addEventListener('focus', () => {
			this.loadSpamPrevention();
		}, {
			once: true,
		});
	}

	async onSubmit(event) {
		event.preventDefault();
		this.submitElement = event.submitter;

		try {
			const formData = new FormData(this.formElement);
			this.resetErrrors();
			this.disableFields();
			this.submitElement.toggleAttribute('data-loader', true);
			const response = await fetch(this.formElement.action, {
				method: this.formElement.method,
				headers: {
					'x-language': this.language,
				},
				body: formData,
			});

			/** @type {ApiResponse} */
			const data = await response.json();
			if (data.key === 'form-errors') {
				this.setFieldErrors(data.details);
				this.resetDisableFields();
				this.submitElement.removeAttribute('data-loader');
			} else if (data.status === 'ok') {
				this.resetDisableFields();
				this.setFieldsToReadonly();
				this.showSuccessMessage(data?.message ?? 'Success');
				this.submitElement.toggleAttribute('hidden', true);
				this.dispatchEvent(new CustomEvent('success'));
			} else if (data.message) {
				throw new Error(`${data?.message}`);
			} else {
				throw new Error('Fatal error: Submission of form failed.');
			}
		} catch (/** @type {Error} */ error) {
			this.resetDisableFields();
			this.submitElement.removeAttribute('data-loader');
			this.showErrorMessage(error?.message ?? 'Fatal error');
		}
	}

	showSuccessMessage(message) {
		this.successElement = Object.assign(document.createElement('div'), {
			className: 'm-form__success',
		});
		this.successElement.innerHTML = `<p>${message}</p>`;
		this.formElement.lastElementChild.after(this.successElement);
	}

	showErrorMessage(message) {
		this.errorElement = Object.assign(document.createElement('div'), {
			className: 'm-form__error',
		});
		this.errorElement.innerHTML = `<p>${message}</p>`;
		this.formElement.lastElementChild.after(this.errorElement);
	}

	unsetFieldsToReadonly() {
		[...this.formElement.elements].forEach((_) => {
			_.toggleAttribute('readonly', false);
		});
	}

	setFieldsToReadonly() {
		[...this.formElement.elements].forEach((_) => {
			_.toggleAttribute('readonly', true);
		});
	}

	disableFields() {
		[...this.formElement.elements].forEach((_) => {
			_.setAttribute('data-disabled', _.hasAttribute('disabled'));
			_.toggleAttribute('disabled', true);
		});
	}

	resetDisableFields() {
		[...this.formElement.elements].forEach((_) => {
			if (_.dataset.disabled === 'false') {
				_.removeAttribute('disabled');
			} else {
				_.toggleAttribute('disabled', true);
			}
			_.removeAttribute('data-disabled');
		});
	}

	resetErrrors() {
		if (this.errorElement) {
			this.errorElement.remove();
		}
		[...this.formElement.elements].forEach((_) => _.removeAttribute('data-error'));
		[...this.formElement.querySelectorAll('.a-field__error')].forEach((_) => _.remove());
	}

	setFieldErrors(errors) {
		Object.keys(errors).forEach((name) => {
			const errorMessage = errors[name][0] ?? null;
			const inputElement = this.formElement.elements[name] ?? null;
			if (errorMessage) {
				if (inputElement) {
					inputElement.toggleAttribute('data-error', true);
					inputElement.closest('.a-field').appendChild(Object.assign(document.createElement('span'), {
						className: 'a-field__error',
						innerText: errorMessage,
					}));
				} else {
					this.showErrorMessage(errorMessage);
				}
			}
		});
	}

	render() {
		return html`
			<slot></slot>
		`;
	}
}

if (!customElements.get('vi-form')) {
	customElements.define('vi-form', ViForm);
}
