class AField {
	/** @param {HTMLElement} element The root element of the component */
	constructor(element) {
		this.element = element;
		this.charCountElement = element.querySelector('.a-field__char-count');
		this.inputElement = element.querySelector('.a-input');

		const onInput = () => {
			this.updateCharCount();
		};

		element.addEventListener('input', onInput);

		this.updateCharCount();
	}

	updateCharCount() {
		const { inputElement, charCountElement } = this;
		const { maxLength } = this.inputElement;
		const valueLength = inputElement.value.length;
		if (maxLength) {
			charCountElement.innerText = `${valueLength}/${maxLength}`;
		} else {
			charCountElement.innerText = valueLength;
		}
	}
}

[...document.querySelectorAll('.a-field[data-feature="char-count"]')].forEach((_) => new AField(_));
