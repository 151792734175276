import katalogProductTypes from './m-product-type.js';

class MCatalogFilter {
	constructor(element) {
		this.element = element;
		this.inputElements = [...element.elements].filter((formControlElement) => formControlElement.type === 'radio');
		this.productTypeElements = document.querySelectorAll('.m-product-type');
		this.buttonResetElement = element.querySelector('button[data-action="reset"]');

		const toggleInputElement = (inputElement) => {
			const labelElement = inputElement.closest('.a-button');
			labelElement.toggleAttribute('data-selected', inputElement.checked);
		};

		this.inputElements.forEach((inputElement) => {
			inputElement.addEventListener('click', () => {
				// toggle all input elements with same name
				inputElement.checked = inputElement.closest('.a-button')?.dataset?.selected !== '';
				this.inputElements.forEach((_inputElement) => {
					if (_inputElement.name === inputElement.name) {
						toggleInputElement(_inputElement);
					}
				});
				this.filter();
			});
		});

		this.buttonResetElement?.addEventListener('click', () => {
			this.inputElements.forEach((inputElement) => {
				inputElement.checked = false;
				toggleInputElement(inputElement);
			});
			this.filter();
		});

		this.updateInputElements();
		this.updateButtonReset();
	}

	get filterObject() {
		return Object.fromEntries((new FormData(this.element)).entries());
	}

	/**
	 * Disables inputElement, when a click of the inputElement would result in zero items.
	 */
	updateInputElements() {
		this.inputElements.forEach((inputElement) => {
			const { filterObject } = this;
			filterObject[inputElement.name] = inputElement.value;
			const filteredMCatalogProductTypes = katalogProductTypes
				.filter((katalogProductType) => katalogProductType.shouldShow(filterObject));
			inputElement.parentElement.setAttribute('aria-disabled', filteredMCatalogProductTypes.length === 0);
			inputElement.toggleAttribute('disabled', filteredMCatalogProductTypes.length === 0);
		});
	}

	updateButtonReset() {
		const hasCheckedInputElements = this.inputElements.filter((_) => _.checked).length > 0;
		this.buttonResetElement?.toggleAttribute('disabled', !hasCheckedInputElements);
	}

	filter() {
		katalogProductTypes.forEach((katalogProductType) => {
			if (katalogProductType.shouldShow(this.filterObject)) {
				katalogProductType.show();
			} else {
				katalogProductType.hide();
			}
		});
		this.updateInputElements();
		this.updateButtonReset();
	}
}

const element = document.querySelector('.m-filter-catalog');
if (element) {
	new MCatalogFilter(element);
}
