import {
	LitElement,
	html,
} from 'lit';

class ViToast extends LitElement {
	#durationMedium = 0;

	static properties = {
		autoHideDuration: { type: Number },
		theme: { type: String },
	};

	constructor() {
		super();
		this.autoHideDuration = 2000;
	}

	connectedCallback() {
		super.connectedCallback();

		if (!this.hasAttribute('role')) {
			this.setAttribute('role', 'alert');
		}

		let durationMedium = parseFloat(getComputedStyle(document.documentElement).getPropertyValue('--duration-medium'));
		durationMedium = Number.isFinite(durationMedium) ? durationMedium : 200;
		this.#durationMedium = durationMedium;

		setTimeout(this.hide.bind(this), this.autoHideDuration + this.#durationMedium);
		this.show();
	}

	show() {
		this.animate([
			{
				opacity: '0',
				translate: '0 var(--_animation-offset-translate)',
			},
			{
				opacity: '1',
				translate: '0 0',
			},
		], {
			duration: this.#durationMedium,
			easing: 'ease-out',
		});
	}

	hide() {
		const animation = this.animate([
			{
				opacity: '1',
				translate: '0 0',
			},
			{
				opacity: '0',
				translate: '0 calc(10rem / 16)',
			},
		], {
			duration: this.#durationMedium,
		});
		animation.addEventListener('finish', () => {
			this.remove();
		});
	}

	render() {
		return html`
			<link rel="stylesheet" href="/assets/css/vi-toast.${BUILT_AT}.css">
			<div class="container">
				<slot></slot>
			</div>
		`;
	}
}

// Define the custom element
if (!customElements.get('vi-toast')) {
	customElements.define('vi-toast', ViToast);
}
