import {
	LitElement,
	html,
	nothing,
} from 'lit';
import { unsafeHTML } from 'lit/directives/unsafe-html.js';
import OLightbox from '../components/o-lightbox.js';
import icons from '../foundation/icons.js';

class ViCarousel extends LitElement {
	static properties = {
		currentIndex: {
			type: Number,
			reflect: true,
		},
		maxCaptionBlockSize: {
			type: Number,
		},
	};

	#itemsCount = null;

	#isDragging = null;

	#dragX = null;

	#dragXStart = null;

	#dragForceX = null;

	#dragDeltaX = 0;

	translations = {
		en: {
			previous: 'Previous',
			next: 'Next',
			navigationLabel: 'Carousel',
		},
		de: {
			previous: 'Vorheriges',
			next: 'Nächstes',
			navigationLabel: 'Karussell',
		},
	};

	constructor() {
		super();

		this.currentIndex = 1;

		this.listElement = this.querySelector(':scope > ul');
		this.itemElements = this.listElement.children;
		this.figcaptionElements = this.listElement.querySelectorAll('figcaption');

		this.#itemsCount = this.listElement.childElementCount;

		let scrollTimer;

		// Functions
		// Utility function to check if an element is partially in view
		const isElementPartiallyInViewport = (element) => {
			const rect = element.getBoundingClientRect();
			const windowHeight = window.innerHeight || document.documentElement.clientHeight;
			const windowWidth = window.innerWidth || document.documentElement.clientWidth;

			return (
				rect.top < windowHeight
				&& rect.bottom > 0
				&& rect.left < windowWidth
				&& rect.right > 0
			);
		};

		// Intersection Observer
		const observer = new IntersectionObserver((entries) => {
			entries.forEach((entry) => {
				entry.target.toggleAttribute('data-is-intersecting', entry.isIntersecting);
			});
			this.#setCurrentItem();
		}, {
			threshold: 0.5, // Check if at least 50% of the element is visible
			root: this,
		});
		[...this.itemElements].forEach((_) => {
			observer.observe(_);
		});

		// Event Listener
		const onScrollEnd = () => {
			if (this.#isDragging === false) {
				Object.assign(this.listElement.style, {
					scrollSnapType: null,
					scrollBehavior: null,
				});
			}
		};
		const onPointerDown = (event) => {
			event.preventDefault();
			this.#isDragging = true;
			this.#dragXStart = event.screenX;
			this.scrollXStart = this.listElement.scrollLeft;
			this.#dragForceX = null;
			this.#dragX = null;
			this.#dragDeltaX = null;
			Object.assign(this.listElement.style, {
				scrollSnapType: 'none',
				scrollBehavior: 'auto',
				cursor: 'grabbing',
			});
		};
		const onPointerMove = (event) => {
			if (this.#isDragging) {
				this.#dragForceX = 1 - this.#dragX / event.screenX;
				this.#dragX = event.screenX;
				this.#dragDeltaX = (this.#dragXStart - event.screenX);
				this.listElement.scroll({
					left: this.scrollXStart + this.#dragDeltaX,
					behavior: 'auto',
				});
			}
		};
		const onPointerUp = () => {
			if (this.#isDragging) {
				let newIndex = this.currentIndex;
				if (Math.abs(this.#dragForceX) > 0.003) {
					if (this.#dragForceX <= 0) {
						newIndex += 1;
					} else {
						newIndex -= 1;
					}
				}
				if (this.#dragDeltaX !== 0) {
					this.showItem(newIndex);
				}
				if (this.#dragDeltaX === null) {
					new OLightbox(this.itemElements[newIndex - 1]);
				}
			}

			this.#isDragging = false;

			Object.assign(this.listElement.style, {
				cursor: null,
			});
		};
		const onKeyDown = (event) => {
			if (!event.shiftKey && !event.altKey && !event.ctrlKey && !event.metaKey) {
				if (isElementPartiallyInViewport(this.listElement)) {
					if (event.key === 'ArrowLeft') {
						event.preventDefault();
						this.#buttonPreviousClick();
					} else if (event.key === 'ArrowRight') {
						event.preventDefault();
						this.#buttonNextClick();
					}
				}
			}
		};
		const onResize = () => {
			this.#setMaxCaptionBlockSize();
		};
		this.listElement.addEventListener('mousedown', onPointerDown);
		window.addEventListener('mousemove', onPointerMove);
		window.addEventListener('mouseup', onPointerUp);
		document.addEventListener('keydown', onKeyDown);
		this.listElement.addEventListener('scroll', () => {
			clearTimeout(scrollTimer);
			scrollTimer = setTimeout(() => {
				onScrollEnd();
			}, 100);
		}, {
			passive: true,
		});
		window.addEventListener('resize', onResize);

		onResize();
	}

	showItem(index) {
		const itemToShowElement = this.itemElements[index - 1];
		if (itemToShowElement) {
			const scrollLeft = itemToShowElement.offsetLeft
				- itemToShowElement.closest('ul').offsetLeft;
			const behavior = window.matchMedia('(prefers-reduced-motion: no-preference)').matches ? 'smooth' : 'instant';
			this.listElement.scroll({
				left: scrollLeft,
				behavior,
			});
		}
	}

	#setCurrentItem = () => {
		const newIndex = [...this.itemElements].findIndex((_) => _.getAttribute('data-is-intersecting') !== null);
		if (newIndex >= 0) {
			this.currentIndex = newIndex + 1;
		}
	};

	#navigtaionItemClick(event) {
		this.showItem(event.target.dataset.index);
	}

	#buttonPreviousClick() {
		this.showItem(this.currentIndex - 1);
	}

	#buttonNextClick() {
		this.showItem(this.currentIndex + 1);
	}

	#setMaxCaptionBlockSize() {
		let maxHeight = null;
		[...this.figcaptionElements].forEach((_) => {
			const elementHeight = _.clientHeight;
			if (elementHeight > maxHeight) {
				maxHeight = elementHeight;
			}
		});
		this.maxCaptionBlockSize = maxHeight;
	}

	render() {
		const language = this.closest('[lang]')?.lang;
		const translations = this.translations[language] ?? this.translations.en;
		const { currentIndex, maxCaptionBlockSize } = this;
		const style = maxCaptionBlockSize ? `--max-caption-block-size: ${maxCaptionBlockSize}px` : nothing;

		return html`
			<link rel="stylesheet" href="/assets/css/vi-carousel.${BUILT_AT}.css">
			<slot></slot>
			<nav class="navigation" aria-label="${translations.navigationLabel}" style="${style}">
				<div class="book-navigation">
					<button
						aria-label="${translations.previous}"
						.disabled="${currentIndex === 1}"
						@click="${this.#buttonPreviousClick}"
					>
						<span></span>
						${unsafeHTML(icons['arrow-left-s-thin'])}
					</button>
					<button
						aria-label="${translations.next}"
						.disabled="${currentIndex === this.#itemsCount}"
						@click="${this.#buttonNextClick}"
					>
						<span></span>
						${unsafeHTML(icons['arrow-right-s-thin'])}
					</button>
				</div>
				<ul class="pagination">
					${Array.from({ length: this.#itemsCount }, (_, index) => index + 1).map((index) => html`<li>
						<button
							data-index="${index}"
							aria-label="${index}"
							.ariaPressed="${currentIndex === index ?? nothing}"
							.ariaCurrent="${currentIndex === index ?? nothing}"
							@click="${this.#navigtaionItemClick}"
						>
							<span></span>
						</button>
					</li>`)}
				</ul>
			</nav>
		`;
	}

	connectedCallback() {
		super.connectedCallback();
	}
}

// Define the custom element
if (!customElements.get('vi-carousel')) {
	customElements.define('vi-carousel', ViCarousel);
}
