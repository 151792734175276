export default {
	'arrow-right-s': '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><polygon points="4.95 6.364 0 1.414 1.414 0 7.778 6.364 1.414 12.728 0 11.314" transform="translate(8 6)"/></svg>',
	'arrow-left-s': '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><polygon points="11.05 12.364 16 7.414 14.586 6 8.222 12.364 14.586 18.728 16 17.314"/></svg>',
	'arrow-right-s-thin': '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><polygon points="9.414 6.337 15.071 11.994 9.414 17.651 8.707 16.944 13.657 11.994 8.707 7.044"/></svg>',
	'arrow-left-s-thin': '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><polygon points="14.586 6.337 15.293 7.044 10.343 11.994 15.293 16.944 14.586 17.651 8.929 11.994"/></svg>',
	'arrow-right': '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><g fill="none" fill-rule="evenodd"><polygon fill-rule="nonzero" points="10.808 5.634 12.222 4.22 20 11.998 12.222 19.776 10.808 18.362 17.17 11.998"/><polygon fill-rule="nonzero" points="18.603 10.998 18.603 12.998 4 12.998 4 10.998"/></g></svg>',
	'arrow-left': '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><g fill="none" fill-rule="evenodd"><polygon fill-rule="nonzero" points="13.192 5.634 11.778 4.22 4 11.998 11.778 19.776 13.192 18.362 6.83 11.998"/><polygon fill-rule="nonzero" points="5.397 10.998 5.397 12.998 20 12.998 20 10.998"/></g></svg>',
	'arrow-drop-down': '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><polygon points="4.243 5.657 0 1.414 1.414 0 4.243 2.828 7.071 0 8.485 1.414" transform="translate(7.755 9)"/></svg>',
	heart: '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M10.0009983,1.52853068 C12.3499983,-0.579999316 15.9799983,-0.509999316 18.2425983,1.75736068 C20.5052983,4.02472068 20.5829983,7.63700068 18.4785983,9.99300068 L9.99989828,18.4850007 L1.52137828,9.99300068 C-0.582951724,7.63700068 -0.504291724,4.01901068 1.75735828,1.75736068 C4.02156828,-0.506849316 7.64518828,-0.583129316 10.0009983,1.52853068 Z M16.8269983,3.17010068 C15.3278983,1.66794068 12.9075983,1.60701068 11.3369983,3.01687068 L10.0018983,4.21524068 L8.66609828,3.01781068 C7.09097828,1.60597068 4.67505828,1.66808068 3.17156828,3.17157068 C1.68182828,4.66131068 1.60703828,7.04730068 2.97992828,8.62320068 L9.99989828,15.6543007 L17.0200983,8.62320068 C18.3934983,7.04670068 18.3189983,4.66525068 16.8269983,3.17010068 Z" transform="translate(2 3)"/></svg>',
	'heart-add': '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M19,15 L19,18 L22,18 L22,20 L18.999,20 L19,23 L17,23 L16.999,20 L14,20 L14,18 L17,18 L17,15 L19,15 Z M20.2425983,4.75736068 C22.5052983,7.02472068 22.5829983,10.6370007 20.4785983,12.9930007 L20.4169605,13.0554929 C20.1164339,13.0188587 19.8104193,13 19.5,13 C18.7436771,13 18.0135019,13.1119513 17.3251413,13.3201867 L19.0200983,11.6232007 C20.3934983,10.0467007 20.3189983,7.66525068 18.8269983,6.17010068 C17.3278983,4.66794068 14.9075983,4.60701068 13.3369983,6.01687068 L12.0018983,7.21524068 L10.6660983,6.01781068 C9.09097828,4.60597068 6.67505828,4.66808068 5.17156828,6.17157068 C3.68182828,7.66131068 3.60703828,10.0473007 4.97992828,11.6232007 L11.9998983,18.6543007 L12.3168559,18.3361828 C12.1107577,19.0213082 12,19.7477197 12,20.5 C12,20.8142049 12.0193215,21.123897 12.0568411,21.427953 L11.9998983,21.4850007 L3.52137828,12.9930007 C1.41704828,10.6370007 1.49570828,7.01901068 3.75735828,4.75736068 C6.02156828,2.49315068 9.64518828,2.41687068 12.0009983,4.52853068 C14.3499983,2.42000068 17.9799983,2.49000068 20.2425983,4.75736068 Z"/></svg>',
	close: '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><polygon points="6.364 4.95 11.314 0 12.728 1.414 7.778 6.364 12.728 11.314 11.314 12.728 6.364 7.778 1.414 12.728 0 11.314 4.95 6.364 0 1.414 1.414 0" transform="translate(6 6)"/></svg>',
	share: '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M11.1202,15.0228 L6.92129,12.7324 C6.19135,13.5125 5.15261,14 4,14 C1.79086,14 0,12.2091 0,10 C0,7.79086 1.79086,6 4,6 C5.15255,6 6.19125,6.48746 6.92118,7.26746 L11.1202,4.97713 C11.0417,4.66441 11,4.33707 11,4 C11,1.79086 12.7909,0 15,0 C17.2091,0 19,1.79086 19,4 C19,6.20914 17.2091,8 15,8 C13.8474,8 12.8087,7.51251 12.0787,6.73246 L7.87977,9.0228 C7.9583,9.3355 8,9.6629 8,10 C8,10.3371 7.95831,10.6644 7.87981,10.9771 L12.0788,13.2675 C12.8087,12.4875 13.8474,12 15,12 C17.2091,12 19,13.7909 19,16 C19,18.2091 17.2091,20 15,20 C12.7909,20 11,18.2091 11,16 C11,15.6629 11.0417,15.3355 11.1202,15.0228 Z M4,12 C5.10457,12 6,11.1046 6,10 C6,8.8954 5.10457,8 4,8 C2.89543,8 2,8.8954 2,10 C2,11.1046 2.89543,12 4,12 Z M15,6 C16.1046,6 17,5.10457 17,4 C17,2.89543 16.1046,2 15,2 C13.8954,2 13,2.89543 13,4 C13,5.10457 13.8954,6 15,6 Z M15,18 C16.1046,18 17,17.1046 17,16 C17,14.8954 16.1046,14 15,14 C13.8954,14 13,14.8954 13,16 C13,17.1046 13.8954,18 15,18 Z" transform="translate(3 2)"/></svg>',
	link: '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M14.4853125,11.6568325 L13.0711125,10.2426325 L14.4853125,8.8284325 C16.0474125,7.2663325 16.0474125,4.7336725 14.4853125,3.1715725 C12.9232125,1.6094825 10.3905125,1.6094825 8.8284125,3.1715725 L7.4142125,4.5857925 L6.0000025,3.1715725 L7.4142125,1.7573625 C9.7573125,-0.5857875 13.5563125,-0.5857875 15.8995125,1.7573625 C18.2426125,4.1005125 18.2426125,7.8995325 15.8995125,10.2426325 L14.4853125,11.6568325 Z M11.6568125,14.4853325 L10.2426125,15.8995325 C7.8995125,18.2426325 4.1005025,18.2426325 1.7573625,15.8995325 C-0.5857875,13.5563325 -0.5857875,9.7573325 1.7573625,7.4142325 L3.1715725,6.0000025 L4.5857825,7.4142325 L3.1715725,8.8284325 C1.6094725,10.3905325 1.6094725,12.9232325 3.1715725,14.4853325 C4.7336725,16.0473325 7.2663125,16.0473325 8.8284125,14.4853325 L10.2426125,13.0710325 L11.6568125,14.4853325 Z M11.6568125,4.5857925 L13.0711125,6.0000025 L6.0000025,13.0710325 L4.5857825,11.6568325 L11.6568125,4.5857925 Z" transform="translate(3 3)"/></svg>',
	loader: '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M18.364,5.63604 L16.9497,7.05025 C15.683,5.7835 13.933,5 12,5 C8.13401,5 5,8.13401 5,12 C5,15.866 8.13401,19 12,19 C15.866,19 19,15.866 19,12 L21,12 C21,16.9706 16.9706,21 12,21 C7.02944,21 3,16.9706 3,12 C3,7.02944 7.02944,3 12,3 C14.4853,3 16.7353,4.00736 18.364,5.63604 Z"/></svg>',
	cart: '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M7,7.099996 L7,6 C7,3.238576 9.23858,1 12.00002,1 C14.76142,1 17.00002,3.238576 17.00002,6 L17.00002,7.099996 L20.00002,7.099996 C20.55232,7.099996 21.00002,7.547716 21.00002,8.099996 L21.00002,21.000036 C21.00002,21.552236 20.55232,22.000036 20.00002,22.000036 L4,22.000036 C3.44772,22.000036 3,21.552236 3,21.000036 L3,8.099996 C3,7.547716 3.44772,7.099996 4,7.099996 L7,7.099996 Z M7,9.199996 L5,9.199996 L5,20.000036 L19.00002,20.000036 L19.00002,9.199996 L17.00002,9.199996 L17.00002,10.900036 L15.00002,10.900036 L15.00002,9.199996 L9,9.199996 L9,10.900036 L7,10.900036 L7,9.199996 Z M9,7.199996 L15.00002,7.199996 L15.00002,5.999996 C15.00002,4.343146 13.65682,2.999996 12.00002,2.999996 C10.34312,2.999996 9,4.343146 9,5.999996 L9,7.199996 Z"/></svg>',
	'cart-add': '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M20,16 L20,19 L23,19 L23,21 L19.999,21 L20,24 L18,24 L17.999,21 L15,21 L15,19 L18,19 L18,16 L20,16 Z M12.00002,1 C14.76142,1 17.00002,3.238576 17.00002,6 L17.00002,7.099996 L20.00002,7.099996 C20.55232,7.099996 21.00002,7.547716 21.00002,8.099996 L21.0010101,14.0710334 C20.6740854,14.0242218 20.3398714,14 20,14 C19.6604758,14 19.3265974,14.0241724 18.9999918,14.07089 L19.00002,9.199996 L17.00002,9.199996 L17.00002,10.900036 L15.00002,10.900036 L15.00002,9.199996 L9,9.199996 L9,10.900036 L7,10.900036 L7,9.199996 L5,9.199996 L5,20.000036 L13.07089,19.9999918 C13.0241724,20.3265974 13,20.6604758 13,21 C13,21.3398714 13.0242218,21.6740854 13.0710334,22.0010101 L4,22.000036 C3.44772,22.000036 3,21.552236 3,21.000036 L3,8.099996 C3,7.547716 3.44772,7.099996 4,7.099996 L7,7.099996 L7,6 C7,3.238576 9.23858,1 12.00002,1 Z M12.00002,2.999996 C10.34312,2.999996 9,4.343146 9,5.999996 L9,7.199996 L15.00002,7.199996 L15.00002,5.999996 C15.00002,4.343146 13.65682,2.999996 12.00002,2.999996 Z"/></svg>',
	'delivery-outline': '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><g fill="none" fill-rule="evenodd"><path fill-rule="nonzero" d="M12,2 C17.5228475,2 22,6.4771525 22,12 C22,17.5228475 17.5228475,22 12,22 C6.4771525,22 2,17.5228475 2,12 C2,6.4771525 6.4771525,2 12,2 Z M12,4 C7.581722,4 4,7.581722 4,12 C4,16.418278 7.581722,20 12,20 C16.418278,20 20,16.418278 20,12 C20,7.581722 16.418278,4 12,4 Z"/><circle cx="12" cy="12" r="3"/></g></svg>',
	'delivery-solid': '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><circle cx="12" cy="12" r="9" fill-rule="evenodd"/></svg>',
};
