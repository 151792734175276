import {
	html,
	render,
} from 'lit';
import { unsafeHTML } from 'lit/directives/unsafe-html.js';
import icons from '../foundation/icons.js';

const translations = {
	en: {
		close: 'Close',
	},
	de: {
		close: 'Schließen',
	},
	es: {
		close: 'Cerrar',
	},
	fr: {
		close: 'Fermer',
	},
};

class OLightbox {
	constructor(targetElement) {
		this.element = Object.assign(document.createElement('dialog'), {
			className: 'o-lightbox',
		});
		this.element.setAttribute('tabindex', '0');
		this.targetElement = targetElement;
		const imgElement = targetElement.tagName === 'IMG' ? targetElement : targetElement.querySelector('img');
		this.imgElement = imgElement?.cloneNode();

		if (this.imgElement) {
			this.imgElement.setAttribute('sizes', '100vw');

			document.addEventListener('keydown', this.#onKeydown.bind(this), {
				capture: true,
				once: true,
			});
			this.element.addEventListener('click', (event) => {
				if (event.target === this.element) {
					this.close();
				}
			});
			this.element.addEventListener('close', () => {
				document.documentElement.style.overflow = null;
			});

			document.querySelector('body').appendChild(this.element);

			this.render();
			this.open();
		}
	}

	get translations() {
		const language = this.element.closest('[lang]')?.lang;
		return translations[language] ?? translations.en;
	}

	#onKeydown(event) {
		if (event.key === 'Escape') {
			this.close();
			event.preventDefault();
			event.stopPropagation(); // Prevent event from bubbling
		}
	}

	render() {
		render(html`
			<form method="dialog">
				<button class="a-button" data-kind="tertiary" data-inverse data-shape="round" data-size="x-large" aria-label="${this.translations.close}">
					${unsafeHTML(icons.close)}
				</button>
			</form>
			${this.imgElement}
		`, this.element);
	}

	open() {
		document.documentElement.style.overflow = 'hidden';

		this.element.showModal();
		this.element.focus();
		this.element.animate({
			opacity: [0, 1],
		}, {
			duration: 400,
			easing: 'ease-out',
		});
	}

	close() {
		const animation = this.element.animate({
			opacity: [1, 0],
		}, {
			duration: 100,
			fill: 'forwards',
		});
		animation.addEventListener('finish', () => {
			this.element.close();
			this.element.remove();
		});
	}
}

document.querySelectorAll('img[data-lightbox]').forEach((_) => _.addEventListener('click', () => new OLightbox(_)));

export default OLightbox;
