import { html, render } from 'lit';

class MSearch {
	// search results
	#results = [];

	#isOpen = false;

	#fadeOutAnimation;

	// number of total results
	#total;

	// object with text and theme. used for error or info.
	#message;

	#language = document.documentElement.lang;

	#fetchController = null;

	#query = null;

	constructor(element) {
		const buttonElement = document.querySelector('.a-button[data-name="search"]');

		this.element = element;
		this.headerBackdropElement = document.querySelector('.o-header__backdrop');
		this.containerElement = element.querySelector('.m-search__container');
		this.formElement = element.querySelector('.m-search__form');
		this.viInputElement = element.querySelector('vi-input');
		this.inputSearchElement = element.querySelector('.a-input[data-variant="search"]');
		this.resultsElement = element.querySelector('.m-search-results');
		this.iconLoadingElement = element.querySelector('.a-icon[data-kind="loader"]');

		this.actionUrl = this.formElement.action;
		this.apiUrl = this.formElement.dataset.actionApi;

		this.resultsElement.hidden = true;

		// Event listeners
		document.addEventListener('keydown', (event) => {
			if (event.key === 'Escape') {
				event.preventDefault();
				this.hide();
			}
			// Check for Cmd + K (Mac) or Ctrl + K (Windows/Linux)
			if ((event.metaKey || event.ctrlKey) && event.key === 'k') {
				if (element.open) {
					this.hide();
				} else {
					this.show();
				}
				event.preventDefault(); // Prevent default action of the key combination
			}
			if (this.#isOpen === true && (event.key === 'ArrowDown' || event.key === 'ArrowUp' || event.key === 'Enter')) {
				const items = this.resultsLinkElements;

				if (event.key === 'ArrowDown') {
					this.activeIndex = (this.activeIndex + 1) % items.length;
					this.highlightItem();
					event.preventDefault();
				} else if (event.key === 'ArrowUp') {
					this.activeIndex = (this.activeIndex - 1 + items.length) % items.length;
					this.highlightItem();
					event.preventDefault();
				} else if (event.key === 'Enter' && this.activeIndex >= 0) {
					items[this.activeIndex].click();
					event.preventDefault();
				}
			}
		});
		this.headerBackdropElement.addEventListener('click', (event) => {
			event.preventDefault();
			this.hide();
		});
		this.element.addEventListener('toggle', (event) => {
			if (event.newState === 'closed') {
				this.hide();
			} else {
				this.show();
			}
		});
		buttonElement.addEventListener('click', (event) => {
			event.preventDefault();
			if (this.#isOpen) {
				this.hide();
			} else {
				this.show();
			}
		});
		this.inputSearchElement.addEventListener('input', () => this.onInputChange());
	}

	get duration() {
		let duration = parseFloat(getComputedStyle(document.documentElement).getPropertyValue('--duration-medium'));
		return Number.isFinite(duration) ? duration : 200;
	}

	async onInputChange() {
		const query = this.inputSearchElement.value.trim();
		this.#query = query;

		// reset
		this.#message = null;
		this.#fetchController?.abort();

		if (query.length >= 3) {
			const data = await this.search(query);
			if (data !== null) {
				this.#results = data.data ?? [];
				this.#total = data.pagination?.total ?? null;
				this.#message = {
					text: data.message ?? null,
					theme: data.status ?? null,
				};
			}
			this.render();
		} else {
			this.resultsElement.hidden = true;
		}
		this.activeIndex = -1;
	}

	get resultsLinkElements() {
		return this.resultsElement.querySelectorAll('a');
	}

	highlightItem() {
		const items = this.resultsLinkElements;
		items.forEach((item, index) => {
			if (index === this.activeIndex) {
				item.setAttribute('aria-selected', 'true');
			} else {
				item.removeAttribute('aria-selected');
			}
		});
	}

	setLoading() {
		this.iconLoadingElement.animate([
			{ opacity: 0 },
			{ opacity: 1 },
		], {
			duration: 200,
			delay: 400,
			fill: 'backwards',
		});
		this.iconLoadingElement.hidden = false;
	}

	removeLoading() {
		this.iconLoadingElement.hidden = true;
	}

	show() {
		if (this.#isOpen === false) {
			this.#isOpen = true;
			this.element.toggleAttribute('data-show-popover', true);

			// hide (mobile) navigation
			document.mNavigation?.hide(true);

			// hide others
			document.aLangSwitch?.hide();

			this.inputSearchElement.focus();

			this.containerElement.animate([
				{ clipPath: 'inset(0 0 100%)' },
				{ clipPath: 'inset(0)' },
			], {
				duration: this.duration,
			});

			this.formElement.animate([
				{ opacity: '0', translate: '0 -0.5rem' },
				{ opacity: '1', translate: '0 0' },
			], {
				delay: this.duration * 0.2,
				duration: this.duration,
				fill: 'backwards',
			});

			// trigger render to correctly set padding
			this.viInputElement.render();

			// wait a frame. other popovers could remove backdrop via hide() method.
			requestAnimationFrame(() => {
				this.headerBackdropElement.toggleAttribute('data-visible', true);
			});
		}
	}

	hide() {
		if (this.#isOpen === true && this.#fadeOutAnimation?.playState !== 'running') {
			this.#fetchController?.abort();

			this.#fadeOutAnimation = this.containerElement.animate([
				{ clipPath: 'inset(0)' },
				{ clipPath: 'inset(0 0 100%)' },
			], {
				duration: this.duration,
			});

			this.formElement.animate([
				{ opacity: '1', translate: '0 0' },
				{ opacity: '0', translate: '0 -0.5rem' },
			], {
				duration: this.duration * 0.9,
			});

			this.headerBackdropElement.toggleAttribute('data-visible', false);

			this.#fadeOutAnimation.addEventListener('finish', () => {
				this.element.toggleAttribute('data-show-popover', false);

				this.inputSearchElement.value = null;
				this.#results = [];
				this.#total = null;
				this.#message = null;

				// render to clean
				this.render();

				this.#isOpen = false;
			});
		}
	}

	/**
	 * @return object with status (e.g. success, error), code (e.g. 200, 500),
	 * message (optional), data (optional)
	 */
	async search(query) {
		try {
			this.#fetchController = new AbortController();

			this.setLoading();
			const response = await fetch(`${this.apiUrl}?q=${encodeURIComponent(query)}&limit=5`, {
				headers: {
					'x-language': this.#language,
				},
				signal: this.#fetchController.signal,
			});
			this.removeLoading();

			if (response) {
				const data = await response.json();
				return data;
			}

			return {
				status: 'error',
				message: response.statusText,
			};
		} catch (error) {
			this.removeLoading();

			// Error 20 is AbortError an comes from this.#fetchController.abort().
			if (error.name !== 'AbortError') {
				return {
					status: 'error',
					message: error.message,
				};
			}
			return null;
		}
	}

	render() {
		render(html`
			${this.#message?.text ?? false ? html`
				<div class="m-search-results__message">
					<div class="a-text" data-theme="${this.#message.theme}">
						${this.#message.text}
					</div>
				</div>
			` : null}
			${this.#results.length > 0 ? html`
				<ul class="m-search-results__list">
					${this.#results.map((result) => html`
						<li class="m-search__result">
							<a href="${result.url}" class="a-search-result">
								${result.image ? html`
									<img src="" srcset="${result.image.srcset}" alt="${result.image.alt}" sizes="72px">
								` : null}
								<div class="a-search-result__text">
									<div class="a-search-result__title">
										<strong>${result.title}</strong>
										${result.subtitle ? html`
											<em>${result.subtitle}</em>
										` : null}
									</div>
									${result.breadcrumbs.length > 0 ? html`
										<ul class="a-search-result__breadcrumbs">
											${result.breadcrumbs.map((_) => html`
												<li>${_}</li>
											`)}
										</ul>
									` : null}
								</div>
							</a>
						</li>
					`)}
				</ul>
				${this.#total ? html`
					<a href="${this.actionUrl}?q=${this.#query}" class="m-search-results__link-all-results">
						Alle ${this.#total} Ergebnisse anzeigen
					</a>
				` : null}
			` : null}
		`, this.resultsElement);

		const show = (this.#message?.text ?? false) || this.#results.length > 0;

		this.resultsElement.hidden = !show;
	}
}

const element = document.querySelector('.m-search');
if (element) {
	document.mSearch = new MSearch(element);
}
